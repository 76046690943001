<template>
  <CRMLayout>
    <template v-slot:header-title>Admin</template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Products</h5>
          </div>

          <div class="main__content">
            <div class="main__content-in">
              <div class="columns">
                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/products/parts/brands">
                        <i class="fa fa-tag"></i> &nbsp;
                        <span>Brands</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/products/parts/categories">
                        <i class="fa fa-list"></i> &nbsp;
                        <span>Part Categories</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/public-uploader">
                        <i class="fa fa-upload"></i> &nbsp;
                        <span>Public uploader</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/products/models">
                        <i class="fa fa-bicycle"></i>
                        <span>Bike Models</span> &nbsp;
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/colors">
                        <i class="fa fa-eyedropper"></i>
                        <span>Build colors</span> &nbsp;
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Customers</h5>
            </div>

            <div class="main__content-in">
              <div class="columns">
                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/customers">
                        <i class="fa fa-user"></i> &nbsp;
                        <span>Customer list</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Settings & Static pages & E-Mails & Errors</h5>
            </div>

            <div class="main__content-in">
              <div class="columns">
                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/pages/create">
                        <i class="fa fa-paperclip"></i> &nbsp;
                        <span>Create new static page</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/pages">
                        <i class="fa fa-pagelines"></i> &nbsp;
                        <span>Static pages list</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/settings">
                        <i class="fa fa-cog"></i> &nbsp;
                        <span>Settings</span>
                      </router-link>
                    </li>
                  </ul>
                </div>

                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/main-page">
                        <i class="fa fa-home"></i> &nbsp;
                        <span>Main page</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/mails">
                        <i class="fa fa-envelope-o"></i> &nbsp;
                        <span>Mails</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/error-codes">
                        <i class="fa fa-exclamation-circle"></i> &nbsp;
                        <span>Error codes</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Support</h5>
            </div>

            <div class="main__content-in">
              <div class="columns">
                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/media">
                        <i class="fa fa-picture-o"></i> &nbsp;
                        <span>Media library</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/media/settings">
                        <i class="fa fa-cogs"></i> &nbsp;
                        <span>Media library settings</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/supports">
                        <i class="fa fa-support"></i> &nbsp;
                        <span>Support</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/supports/categories">
                        <i class="fa fa-group"></i> &nbsp;
                        <span>Add support category</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/supports/create">
                        <i class="fa fa-plus"></i> &nbsp;
                        <span>Add Support Article</span>
                      </router-link>
                    </li>
                    <li v-if="canViewRoles()">
                      <router-link to="/roles">
                        <i class="fa fa-plus"></i> &nbsp;
                        <span>Roles</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Vacancies & CV</h5>
            </div>

            <div class="main__content-in">
              <div class="columns">
                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/cv">
                        <i class="fa fa-user-plus"></i> &nbsp;
                        <span>CV list</span>
                      </router-link>
                    </li>
                  </ul>
                </div>

                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/vacancies/create">
                        <i class="fa fa-briefcase"></i> &nbsp;
                        <span>Create new vacancy</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/vacancies">
                        <i class="fa fa-list"></i> &nbsp;
                        <span>List of vacancies</span>
                      </router-link>
                    </li>
                  </ul>
                </div>

                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/departments/create">
                        <i class="fa fa-briefcase"></i> &nbsp;
                        <span>Create new vacancy department</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link tag="a" to="/departments">
                        <i class="fa fa-list"></i> &nbsp;
                        <span>List of vacancy departments</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Dealers</h5>
            </div>

            <div class="main__content-in">
              <div class="columns">
                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/dealers">
                        <i class="fa fa-list"></i> &nbsp;
                        <span>Dealer List</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/dealers/create">
                        <i class="fa fa-plus"></i>
                        <span>Create Dealer</span> &nbsp;
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Demo and Service partners</h5>
            </div>

            <div class="main__content-in">
              <div class="columns">
                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/demo-and-service-partners">
                        <i class="fa fa-list"></i> &nbsp;
                        <span>Demo and Service partners List</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="column">
                  <ul>
                    <li>
                      <router-link to="/demo-and-service-partners/create">
                        <i class="fa fa-plus"></i> &nbsp;
                        <span>Create Partner</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import "font-awesome/css/font-awesome.css";

export default {
  name: "CRMAdmin",
  components: { CRMLayout },
  methods: {
    canViewRoles() {
      return JSON.parse(localStorage.getItem("user")).user_type_id === 1;
    }
  }
};
</script>

<style scoped>
.columns {
  display: flex;
}

.column {
  width: 25%;
}

.column ul {
  list-style-position: outside;
  list-style-type: none;
}

.column ul li {
  margin-bottom: 20px;
}

.column ul li a {
  color: #000000;
  text-decoration: none;
}

@media screen and (max-width: 1199px) {
  .column {
    width: 50%;
  }
}

@media screen and (max-width: 575px) {
  .column ul {
    padding-left: 0;
    margin: 0;
  }

  .column ul li:last-child {
    margin-bottom: 0;
  }

  .main__content-headline {
    padding-left: 18px;
  }

  .column {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .column:last-child {
    margin-bottom: 0;
  }

  .columns {
    flex-wrap: wrap;
  }

  .main__content-in {
    padding: 32px 18px;
  }

  .main__content-headline h5 {
    padding-left: 0;
  }
}
</style>
